/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */


// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';

// start the Stimulus application
import './bootstrap';

import $ from 'jquery';
// uncomment if you have legacy code that needs global variables
global.$ = global.jQuery = $;
// import '/assets/js/bundles/app/js/chosen-custom'
import 'bootstrap3'; // adds functions to jQuery
import './js/app/before-ready'
import 'jquery-confirm';

/*<script src="https://cdnjs.cloudflare.com/ajax/libs/modernizr/2.8.3/modernizr.min.js"></script>*/
//to update routes: run command "php bin/console fos:js-routing:dump --format=json --target=public/js/fos_js_routes.json"
const routes = require('/public/js/fos_js_routes.json');
import Routing from '../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
Routing.setRoutingData(routes);


// import 'viz.js'

import './js/app/viz'
import moment from 'moment'
window.moment = moment
import 'moment/locale/nl'
import 'interactjs'
import 'angular'
// <script src="{{ asset('lib/scripts/angular-route.min.js') }}"></script>
import 'angular-resource'
import 'angular-animate'
import 'angular-ui-bootstrap/dist/ui-bootstrap-tpls'
import 'angular-ui-utils/modules/utils'
import 'rrule'
// <script src="//cdnjs.cloudflare.com/ajax/libs/angular-bootstrap-colorpicker/3.0.25/js/bootstrap-colorpicker-module.min.js"></script>
import 'angular-bootstrap-calendar/dist/js/angular-bootstrap-calendar-tpls'
// <!-- Datepicker -->
require('eonasdan-bootstrap-datetimepicker')
// import 'bootstrap-datepicker'
require('bootstrap-datepicker')
// <script src="{{ asset('bundles/nav-popout/js/main.js') }}"></script>
// <!-- Duallist -->
// <script type="text/javascript" language="javascript" src="{{ asset('bundles/bootstrap-duallist/js/jquery.bootstrap-duallistbox.js') }}"></script>
// <!-- Angular Duallist -->
// <script type="text/javascript" language="javascript" src="{{ asset('bundles/angular-bootstrap-duallistbox/common/module.js') }}"></script>
// <script type="text/javascript" language="javascript" src="{{ asset('bundles/angular-bootstrap-duallistbox/src/directives/bsDuallistbox.js') }}"></script>

// import 'yadcf'
// import '/assets/js/bundles/app/js/yadcf-custom'
// <!-- Datatables -->
// <script type="text/javascript" language="javascript" src="{{ asset('bundles/app/js/yadcf-custom.js') }}"></script>
// <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.36/pdfmake.min.js"></script>
// <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.36/vfs_fonts.js"></script>
// <script type="text/javascript" src="https://cdn.datatables.net/v/bs/jszip-2.5.0/dt-1.10.18/af-2.3.3/b-1.5.6/b-colvis-1.5.6/b-flash-1.5.6/b-html5-1.5.6/b-print-1.5.6/cr-1.5.0/fc-3.2.5/fh-3.1.4/kt-2.5.0/r-2.2.2/rg-1.1.0/rr-1.2.4/sc-2.0.0/sl-1.3.0/datatables.min.js"></script>


// import 'datatables.net-bs'
// import 'datatables.net-buttons'
// import 'datatables.net-colreorder-bs'
// import 'datatables.net-responsive-bs'
// import 'datatables.net-plugins/dataRender/datetime'

import './js/app/sidebar'
import './js/app/actions'
import './js/app/styles'
import './js/app/chart'
import './js/app/chart-lego'
import './js/app/init'
import './js/app/calendar'

import 'chart.js'
import 'angular-bootstrap-calendar'
import './js/app/calendar_helper'

import 'api-check'
import 'angular-formly'
import 'angular-formly-templates-bootstrap'

import 'jquery-clockpicker'
import 'clockpicker/dist/bootstrap-clockpicker'

import 'angular-messages'
import 'angular-animate'


import './js/app/collections'
import './js/app/lego-collection'
import './js/app/collections-conditional-groups'
import './js/app/collections-instruction-lists'
import './js/app/ElementController'
import './js/app/SymfonyAngularForm'
import './js/app/TaskController'
// import './js/app/notifications'
import './js/app/user_invitation'
import './js/app/angular-datatable-custom'
// <script src="//cdn.rawgit.com/niftylettuce/bootstrap-datepicker-mobile/9999ca720ebf89600bda1659c96a291dc447ff39/bootstrap-datepicker-mobile.js"></script>
import './js/app/collections-contract'
// import './js/app/collections-contract-task'
import './js/app/collections-contract-lego'
import './js/app/collections-contractparts'


